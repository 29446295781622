<template>
  <div class="google-totp-login-root">
    <TotpComponent />
    <div class="google-totp-login-row">
      <otp-input
        ref="otp"
        v-model="authCode"
        :length="6"
        pattern="[^0-9]+"
        :ignorePattern="false"
        @change="onCodeChange"
      />
    </div>
    <div class="google-totp-login-btns flex max-w-[400px] justify-between mx-auto">
      <OutlineButton
        class="outline-button btn-bright-large"
        ref="submitButton"
        text="confirm"
        fullWidth
        @click="handleSubmitVerify"
        :disabled="disabledSubmit"
      />
      <Button
        fullWidth
        :brightBtn="false"
        size="large"
        text="cancel"
        @click="handleCancelVerify"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Button from '@/components/_common/FormElements/Button/Button';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import TotpComponent from '@/components/_common/TotpComponent/TotpComponent';

export default {
  components: {
    Button,
    OutlineButton,
    TotpComponent,
  },
  data: () => ({
    authCode: '',
    disabledSubmit: true,
  }),
  methods: {
    ...mapActions('user', ['verifyGoogleAuthTotp', 'logoutAction']),
    onCodeChange(value) {
      this.authCode = value;
      this.disabledSubmit = this.authCode.length < 6;
      if (this.authCode.length === 6) {
        this.$nextTick(() => this.$refs.submitButton.focusButton());
      } else {
        setTimeout(() => { this.$refs.otp.$refs[`otpFields-${this.authCode.length}`].focus() }, 50);
      }
    },
    handleCancelVerify() {
      this.$router.push({ name: 'login' });
      this.logoutAction();
    },
    handleSubmitVerify() {
      this.verifyGoogleAuthTotp(this.authCode)
        .then(token => {
          if (token) {
            this.$router.push({
              name: this.$route.query.invoice ? 'invoicing.pay' : this.$route.query.zendesk_auth ? 'zendesk' : 'account',
              params: this.$route.query.invoice ? { invoice: this.$route.query.invoice } : '',
              query: this.$route.query.zendesk_auth ? { brand_id: 'true' } : '',
            });
          }
        })
        .catch(() => {
          this.authCode = '';
          this.$nextTick(() => this.$refs.otp.$refs['otpFields-0'].focus());
        });
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.otp.$refs['otpFields-0'].focus());
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
.google-totp-login-btns {
  button {
    flex: 0 1 49%;
  }
}

.google-totp-login-row {
  height: 30px;
  max-width: 390px;
  margin: 60px auto;

  & > ul {
    padding: 0 !important;
    display: grid !important;
    list-style-type: none;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 8px;

    & > li {
      padding-bottom: 6px;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        position: absolute;
        height: 4px;
        bottom: 0;
        left: 0;
        background-color: $primaryLanding;
        box-shadow: 0 0 10px rgba(42, 243, 243, 0.55);
      }
      input {
        padding: 0;
        height: 30px;
        width: 100%;
        font-size: 28px;
        width: 100%;
        padding: 0;
        outline: none;
        color: $primaryLanding;
        font-weight: 600;
        border-radius: 0 !important;
        border: none !important;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        -moz-appearance: textfield;
      }
    }
  }
}
</style>
