<template>
  <div class="GoogleTotpComponent_root">
    <img src="@/assets/img/auth/G2FA-logo.svg" alt="authenticator-icon" />
    <div class="GoogleTotpComponent_container">
      <div class="GoogleTotpComponent_title">google authentication</div>
      <div class="GoogleTotpComponent_description">Enter the 6 digit code sent to Google authentication</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';

.GoogleTotpComponent_root {
  display: flex;
  align-items: center;
  padding: 23px 20px;
  background: rgba(#2af3f3, 0.1);
  border-radius: 4px;
  max-width: 390px;
  margin: 0 auto 30px auto;
}
.GoogleTotpComponent_description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-left: 8px;
}
.GoogleTotpComponent_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $primaryLanding;
  margin: 0 0 10px 0;
}
.GoogleTotpComponent_stepLabelDescText {
  color: $primaryLanding;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
}
</style>
